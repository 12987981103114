import React from 'react';
import aboutusBanner from '../assets/images/banners/about.jpg';
import aboutusImg from '../assets/images/banners/aboutus.jpg';
import ReactCountryFlag from "react-country-flag";

const AboutUs = () => {
  return (
    
    <div className="container-fluid py-3">
      <div className="container">
      <div class="banner-image">
            <img className="img-fluid rounded" alt="About Us" src={aboutusBanner} />
        </div>
        <div className="container py-3">
        <h2 className="fw-bold text-primary text-uppercase text-center">Transforming Businesses with Smart Digital Solutions</h2> </div>
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12 mb-4 mb-lg-0">
            {/* <div className="section-title position-relative pb-3 mb-1">
              <h1 className="mb-0">Empowering Businesses through Innovative Solutions</h1>
            </div> */}
            <p className="mb-4">At Neinus, we are dedicated to providing reliable and efficient digital solutions to small and medium-sized businesses. Our mission is to empower our clients to achieve their goals and drive business growth by leveraging the latest technologies and best practices.</p>
            <p className="mb-4">Our company specializes in website development, app development, and digital marketing services, offering comprehensive solutions for businesses seeking to establish a strong online presence. From responsive websites to feature-rich mobile applications, we design and develop digital experiences that enhance user engagement and drive results.</p>
            <p className="mb-4">In addition, our digital marketing services ensure businesses maximize their reach through SEO, social media marketing, PPC campaigns, and content strategies tailored to their unique needs. Our team of experienced professionals is committed to delivering high-quality solutions that help clients stay ahead in the ever-evolving digital landscape.</p>
            <p>With expertise in web and app development, as well as data-driven marketing strategies, we provide end-to-end solutions tailored to each client. Whether it's building a custom website, developing a cutting-edge mobile app, or executing a results-driven digital marketing campaign, we are committed to delivering excellence in every project.</p>
            <div className="row mb-3 col-md-12">
              <div className="col-md-6">
                <h5 className="mb-3"><i className="fa fa-check text-primary me-2"></i>Customized Solutions</h5>
                <h5 className="mb-3"><i className="fa fa-check text-primary me-2"></i>Expert Guidance</h5>
              </div>
              <div className="col-md-6">
                <h5 className="mb-3"><i className="fa fa-check text-primary me-2"></i>Continuous Support</h5>
                <h5 className="mb-3"><i className="fa fa-check text-primary me-2"></i>Transparent Communication</h5>
              </div>
            </div>
            <div className="d-flex align-items-center mb-4">
            
              <div className="card-text ps-3">
              <h5 className="mb-1 text-primary">Contact NEINUS for your Requirements: </h5>
                <div className="mb-2">
                  <ReactCountryFlag countryCode="IN" svg className="me-2" />
                  <strong>India</strong>
                   <p><strong> Sathyamangalam: </strong> No 139/1A,Kamadhenu Nagar, Opposite To BIT College , Sathy Athani Road Bus Stop, Sathyamangalam - 638503 , Erode Dt, TamilNadu<br/>
                  <strong className='text-primary fw-bold'> +91 7200864623 , +91 9965618507</strong>  </p>
                </div>
                <div className="mb-2">
                  <ReactCountryFlag countryCode="US" svg className="me-2" />
                  <strong>USA</strong>
                   <p>
                  <strong className='text-primary fw-bold'>+1 480 261 3864</strong>  </p>
                </div>
              </div>
            </div>
            <a href="/services" className="btn btn-primary py-3 px-5 mt-3">Get Started</a>
          </div>
          <div className="col-lg-5 col-md-12">
            <img className="img-fluid rounded drop-shadow" alt="About Us" src={aboutusImg} />
          </div>
        </div>
      </div>
    
    </div>
  
  );
}

export default AboutUs;
