// Home.js
import React from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from 'react-bootstrap'; // Import Container, Row, and Col from react-bootstrap
import ImageCarousel from './ImageCarousel';
import AboutUs from './About';
import Services from './Services';
import Projects from './Projects';
// import OurServices from './OurServices'

import smImage from '../images/sm_x.jpg'; // Import the image using a relative path

const Home = () => {
  const images = [
    {
      src: require('../images/banner04.jpg'),
      caption: 'Software Training Solutions',
      description: "Providing top-notch software training and development services to enhance your team's technical skills."
    },
    {
      src: require('../images/banner02.jpg'),
      caption: 'Streamline Your IT Operations',
      description: 'Efficient & cost-effective IT services for businesses .'
    },
    {
      src: require('../images/banner05.jpg'),
      caption: 'Empower Yourself!',
      description: 'Elevate Your Experience with Essential Software Skills'
    }
  ];

 
  return (
    <div className="home">
      <Helmet>
        <title>Neinus Technologies -  IT Services & Software Training in Sathyamangalam</title>
        <meta
          name="description"
          content="Neinus Technologies offers expert software training, software development, and IT services in Sathyamangalam. Discover top-notch software solutions and IT consultancy from a leading software company in the region."
        />
        <meta
          name="keywords"
          content="Software Training Institute, Software Development, IT Services, Erode, Sathyamangalam, Neinus Technologies, Software Company, IT Solutions, Software Courses, IT Consultancy"
        />
        <meta
          property="og:title"
          content="Neinus Technologies - Leading IT Services and Training in Sathyamangalam"
        />
        <meta
          property="og:description"
          content="Providing comprehensive software training, software development, and IT solutions in Sathyamangalam. Partner with Neinus Technologies for innovative IT services."
        />
        <meta property="og:image" content={smImage} />
        <meta property="og:url" content="https://neinus.com" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Neinus Technologies - Expert IT Services & Training" />
        <meta
          name="twitter:description"
          content="Join Neinus Technologies for expert IT solutions and training in Sathyamangalam. Erode "
        />
        <meta name="twitter:image" content={smImage} />
      </Helmet>
      <Row>
          <Col>
            <ImageCarousel images={images} />
          </Col>
        </Row>
      <Container>
       
        <Row>
          <Col>
            <main className="content">
              <AboutUs />
            </main>
          </Col>
        </Row>
        {/* <Row>
          <Col>
            <main className="content">
              <OurServices />
            </main>
          </Col>
        </Row> */}
        <Row>
          <Col>
            <main className="content">
              <Services />
            </main>
          </Col>
        </Row>
        <Row>
          <Col>
            <main className="content">
              <Projects />
            </main>
          </Col>
        </Row>
        {/* <Row>
          <Col>
            <main className="content">
              <Clients clients={clientData} />
            </main>
          </Col>
        </Row> */}
        {/* Uncomment this block if you want to include the ContactUs component */}
        {/* <Row>
          <Col>
            <main className="content">
              <ContactUs />
            </main>
          </Col>
        </Row> */}
      </Container>
    </div>
  );
}

export default Home;
