import React, { useState, useEffect } from "react";
import emailjs from "emailjs-com";
import 'bootstrap/dist/css/bootstrap.min.css';
import contactusBanner from '../assets/images/banners/contactus.jpg';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    service: "", // New field for dropdown
    message: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    phone: "",
    email: "",
    service: "", // Error for dropdown
    message: "",
  });

  const [loading, setLoading] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    setLocations([
      {
        name: "Regd Office:",
        address:
          "No 139/1A, Kamadhenu Nagar, Opposite To BIT College, Sathy Athani Road Bus Stop, Sathyamangalam - 638503, Erode Dt, TamilNadu",
        phone: " +91 7200864623 , +91 9965618507",
      },
      {
        name: "Sathyamangalam:",
        phone: " +91 7200864623 , +91 9965618507",
      },
      {
        name: "Dharmapuri:",
        phone: "+91 9042401577",
      },
      {
        name: "USA:",
        phone: "+1 4802613864",
      },
    ]);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^[0-9]{10,10}$/;

    // Validation rules
    const validationRules = {
      name: value.length > 0 ? "" : "Name is required",
      phone: phoneRegex.test(value) ? "" : "Please enter a valid phone number",
      email: emailRegex.test(value) ? "" : "Please enter a valid email address",
      service: value.length > 0 ? "" : "Please select a service", // Validation for dropdown
      message: value.length > 0 ? "" : "Message is required",
    };

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: validationRules[name] || "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (Object.values(errors).every((error) => error === "")) {
      setLoading(true);
      try {
        await emailjs.send(   
        "service_3g3rqri",
        "template_amdg2te",
        formData,
        "22Rk1x7nRvWpUw37o"
        );
        setToastMessage({
          type: "success",
          text: "Your message has been sent successfully!",
        });
        setFormData({ name: "", phone: "", email: "", service: "", message: "" });
      } catch (error) {
        setToastMessage({
          type: "error",
          text: "Failed to send your message, please try again.",
        });
      } finally {
        setLoading(false);
      }
    } else {
      setToastMessage({
        type: "error",
        text: "Please correct the errors before submitting.",
      });
    }
  };

  useEffect(() => {
    if (toastMessage) {
      const timer = setTimeout(() => setToastMessage(null), 5000);
      return () => clearTimeout(timer);
    }
  }, [toastMessage]);

  return (
    <div className="container my-3">
      <div className="banner-image">
        <img
          className="img-fluid rounded"
          alt="Contact Us"
          src={contactusBanner}
        />
      </div>
      <div className="row my-3 ml-3">
        {/* Contact Form */}
        <div className="col-md-6">
          <div className="card shadow">
            <div className="card-body">
              <h2 className="card-title mb-4 text-primary">Contact Us</h2>
              <form onSubmit={handleSubmit}>
                {toastMessage && (
                  <div
                    className={`alert ${
                      toastMessage.type === "success"
                        ? "alert-success"
                        : "alert-danger"
                    } mb-3`}
                    role="alert"
                  >
                    {toastMessage.text}
                  </div>
                )}

                {/* Name Input */}
                <div className="mb-3 text-start">
                  <label htmlFor="name" className="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className={`form-control w-75 ${
                      errors.name ? "is-invalid" : ""
                    }`}
                    required
                  />
                  {errors.name && (
                    <div className="invalid-feedback">{errors.name}</div>
                  )}
                </div>

                {/* Phone Input */}
                <div className="mb-3 text-start">
                  <label htmlFor="phone" className="form-label">
                    Phone Number
                  </label>
                  <input
                    type="text"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    className={`form-control w-75 ${
                      errors.phone ? "is-invalid" : ""
                    }`}
                    required
                  />
                  {errors.phone && (
                    <div className="invalid-feedback">{errors.phone}</div>
                  )}
                </div>

                {/* Email Input */}
                <div className="mb-3 text-start">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className={`form-control w-75 ${
                      errors.email ? "is-invalid" : ""
                    }`}
                    required
                  />
                  {errors.email && (
                    <div className="invalid-feedback">{errors.email}</div>
                  )}
                </div>

                {/* Service Dropdown */}
                <div className="mb-3 text-start">
                  <label htmlFor="service" className="form-label">
                  Tell us about your project 
                  </label>
                  <select
                    id="service"
                    name="service"
                    value={formData.service}
                    onChange={handleChange}
                    className={`form-control w-75 ${
                      errors.service ? "is-invalid" : ""
                    }`}
                    required
                  >
                    <option value="">Select a Service</option>
                    <option value="Website Development">Website Development</option>
                    <option value="App Development">App Development</option>
                    <option value="Digital Marketing">Digital Marketing</option>
                    <option value="SEO Optimization">SEO Optimization</option>
                    <option value="Brand Identity">Brand Identity</option>
                    <option value="Social Media Marketing">Social Media Marketing</option>
                  </select>
                  {errors.service && (
                    <div className="invalid-feedback">{errors.service}</div>
                  )}
                </div>

                {/* Message Input */}
                <div className="mb-3 text-start">
                  <label htmlFor="message" className="form-label">
                    Message
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    className={`form-control w-75 ${
                      errors.message ? "is-invalid" : ""
                    }`}
                    rows="4"
                    placeholder="Type your message here..."
                    required
                  />
                  {errors.message && (
                    <div className="invalid-feedback">{errors.message}</div>
                  )}
                </div>

                {/* Submit Button */}
                <div className="d-flex justify-content-center">
                  <button
                    type="submit"
                    className="btn btn-primary w-50"
                    disabled={loading}
                  >
                    {loading ? "Sending..." : "Submit"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* Location Details */}
        <div className="col-md-5">
          <div className="card shadow">
            <div className="card-body">
              <h2 className="card-title mb-4 text-primary">Our Location</h2>
              {locations.map((location, index) => (
                <div key={index} className="mb-4 text-start">
                  <h5 className="fw-bold">{location.name}</h5>
                  <p>{location.address}</p>
                  <p>
                    <strong>Phone:</strong>
                    <p>{location.phone}</p>
                  </p>
                </div>
              ))}
              <div>
                <p className=" text-start">
                  <strong>Email:</strong>{" "}
                  <p>
                    contactus@neinus.com
                    <br />
                    neinustech@gmail.com
                  </p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
