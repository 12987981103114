import React from 'react';
import portfolioBanner from '../assets/images/banners/portfolio.jpg';
import Portfolio from './Portfolio';

import kceImage from '../assets/images/portfolio/kce.png';
import btraditionalsImage from '../assets/images/portfolio/btraditionals.png';
import mmsImage from '../assets/images/portfolio/mms.png';
import amiImage from '../assets/images/portfolio/ami.png';
import mnImage from '../assets/images/portfolio/mn.png';

const clients = [
    
    {
      title: 'B Traditionals',
      image: btraditionalsImage,
      description: {
        Title: 'B Traditionals',
        Description: "This project involves the creation of a user-friendly online store tailored for selling traditional men's and juniors' wear across the USA. The target audience includes men and boys seeking high-quality traditional attire for weddings, festivals, and cultural events, as well as parents shopping for juniors.",
        KeyFeatures: [
            'Responsive Design: Seamless shopping experience across desktops, tablets, and mobile devices.',
            "Product Categories: Easy navigation with filters for men's wear, juniors' wear, accessories, and occasion-based outfits.",
            'Secure Payment Integration: Multiple payment options, including credit cards and digital wallets.',
            'Wishlist and Cart: Save favorite items and manage purchases effortlessly.',
            'Customer Support: Live chat and FAQs for quick assistance.',
            'Fast Shipping: Nationwide delivery with tracking options.',
            'SEO-Optimized Content: Enhanced discoverability for relevant traditional clothing keywords.'                    
          ],
          targetAudience: [
      "Families: Parents buying traditional wear for children for weddings, festivals, and religious events.",
"Young Adults & Professionals: Men seeking stylish yet traditional attire for formal occasions.",
"Online Shoppers: Customers who prefer shopping for traditional wear online.",
"NRI (Non-Resident Indians): People living abroad wanting to stay connected to their roots through traditional clothing.",
       ]       },
        website: 'https://btraditionals.com',
  },
  {
    title: 'Kaamadhenu College of Education',
    image: kceImage,
    description: {
      Title: 'Kaamadhenu College of Education',
      Description: "Kaamadhenu College of Education is a prominent institution dedicated to providing high-quality teacher training and education. The project focuses on developing a comprehensive online presence for the college to showcase its academic offerings, campus life, faculty expertise, and student achievements. The platform serves as a resource for prospective students, educators, and stakeholders looking to learn more about the college's vision and the impact it has on the future of education.",
      KeyFeatures: [
       "Institution Overview: A detailed introduction to the college, its mission, and core values.",
      "Courses and Programs: Information about various teacher training programs, courses, and certifications offered by the college.",
      "Faculty and Staff: Highlighting the qualifications and experience of the college's experienced faculty members.",
      "Campus Life: Showcasing the vibrant campus environment with photos, student activities, and events.",
      "Student Testimonials: Insights from current students and alumni about their experiences and the value of the education they received.",
      "Events and Workshops: Calendar and information on upcoming educational workshops, seminars, and community outreach programs."
              
        ],
        targetAudience: [
          "Aspiring educators looking to pursue a career in teaching and education.",
         "Parents and guardians seeking information about quality education for their children.",
          "Education professionals looking for opportunities to enhance their skills and qualifications.",
          "Alumni and stakeholders interested in staying connected with the college community.",
        ]
      },
      website: 'https://kcesathy.ac.in',
      
  },
  {
    title: 'AMI Engineering',
    image: amiImage,
    description: {
      Title: 'AMI Engineering',
      Description: "At AMI Engineering, we specialize in delivering precision-machined components using state-of-the-art CNC machinery. Our expertise lies in producing high-quality parts that meet rigorous industry standards. With a dedication to innovation and excellence, we craft every component with unparalleled attention to detail, ensuring reliability and superior performance.",
      KeyFeatures: [
        'Advanced CNC Machines: Precision-engineered components with state-of-the-art CNC technology.',
        'ISO 9001:2015 Certified: Guaranteed consistency and reliability through a recognized quality management system.',
        'High Precision: Uncompromising accuracy for intricate and complex designs.',
        'Custom Manufacturing: Tailored solutions to meet diverse client requirements across industries.',
        'Material Versatility: Expertise in working with a wide range of materials, from metals to composites.',
        'Quality Assurance: Rigorous inspections and testing ensure every component meets or exceeds standards.',
        'Innovation-Driven: Continuous improvement and adoption of cutting-edge techniques for superior results.'
    ],
    targetAudience: [
        'Aerospace and Defense: Companies needing precision-machined components for critical applications.',
        'Medical Device Manufacturers: Businesses requiring intricate parts for medical equipment and tools.',
        'Automotive Industry: Manufacturers seeking reliable and precise parts for high-performance vehicles.',
        'Industrial Machinery Providers: Industries needing custom parts for heavy-duty equipment and machinery.',
        'Electronics and Technology: Companies requiring detailed and high-quality components for advanced systems.',
        'Renewable Energy Sector: Firms developing solar, wind, and other sustainable technologies needing durable components.'
    ]     },
      website: 'https://amiengineering.in',
  },
    {
      title: 'Madurai Meenakshi Sculptures',
      image: mmsImage,
      description: {
        Title: 'Madurai Meenakshi Sculptures',
        Description: 'Madurai Meenakshi Sculptures is a tribute to the rich cultural heritage of Tamil Nadu, specifically showcasing the intricate craftsmanship of traditional Indian artisans. The project is centered around creating a digital platform that highlights sculptures inspired by the iconic Madurai Meenakshi Temple, renowned for its architectural grandeur and artistic legacy. This platform caters to art enthusiasts, collectors, and tourists, offering them a virtual gallery to explore and appreciate these timeless creations.',
        KeyFeatures: [
          'Virtual Gallery: A visually appealing digital display of traditional sculptures with high-quality images and detailed descriptions.',
            'Cultural Insights: Informative sections detailing the history and significance of each sculpture and its connection to South Indian culture.',
            "Customization Options: Users can request personalized sculptures or specific designs inspired by the temple's architecture.",
            'Online Purchase: A seamless e-commerce experience to purchase sculptures directly from artisans, promoting local craftsmanship.',
            'Mobile-Optimized Experience: A responsive design ensuring a smooth browsing experience across devices.',
          ],
          targetAudience: [
            "Art enthusiasts and collectors passionate about traditional Indian art.",
             "Travelers and tourists seeking souvenirs or mementos reflecting South Indian heritage.",
             "Academicians and students interested in learning about temple architecture and sculpture.",
             "Buyers looking for authentic handcrafted sculptures for decor or gifting purposes."
          ]
        },
        website: 'https://mmsculptures.com',
  },
  {
    title: 'M.N Interior & Exterior',
    image: mnImage,
    description: {
      Title: 'M.N Interior & Exterior',
      Description: "M.N Interior & Exterior specializes in high-quality aluminum fabrication, interior, and exterior design solutions for residential and commercial spaces across Tamil Nadu. The company focuses on creating functional, aesthetic, and durable spaces tailored to client needs.",
      KeyFeatures: [
          'Custom Interior Design: Personalized solutions for residential and commercial spaces.',
          'Exterior Design Expertise: Facade renovations, landscape design, and durable finishes.',
          'Aluminum Fabrication: High-quality doors, windows, partitions, curtain walls, and railings.',
          'Professional Craftsmanship: Skilled team ensuring precision and quality in every project.',
          'Seamless Project Execution: Timely delivery with efficient communication and planning.',
          'Eco-Friendly Options: Sustainable materials and energy-efficient designs.',
          'Client-Centric Approach: Tailored solutions based on client preferences and requirements.'                    
        ],
        targetAudience: [
    "Homeowners: Individuals looking to enhance their living spaces with custom interiors and exteriors.",
    "Businesses & Offices: Companies seeking modern and functional workspace designs.",
    "Real Estate Developers: Builders requiring premium fabrication and design solutions.",
    "Retail & Hospitality: Restaurants, cafes, and commercial establishments needing aesthetic upgrades.",
    "Architects & Designers: Professionals collaborating on aluminum fabrication and design projects."
     ]       
    },
    website: 'https://mninteriorandexterior.com',
}
    
  ];
  
  const Projects = () => {
    return (
      <div className="container py-3">
        <div className="banner-image">
          <img className="img-fluid rounded" alt="Projects" src={portfolioBanner} />
        </div>
  
        <div className="container py-3">
          <h2 className="fw-bold text-primary text-uppercase text-center">Our Projects</h2>
          <Portfolio projects={clients} />
        </div>
      </div>
    );
  };
  
  export default Projects;
