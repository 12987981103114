import React, { useState, useEffect } from "react";
import "../assets/css/imageCarousel.css"; // Custom styles

// Helper function to load images dynamically
const importAll = (r) => r.keys().map(r);

const desktopImages = importAll(
  require.context("../assets/images/carousel", false, /\.(jpg|jpeg|png|gif)$/)
);

const mobileImages = importAll(
  require.context("../assets/images/carousel/mobile", false, /\.(jpg|jpeg|png|gif)$/)
);

const ImageCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const images = isMobile ? mobileImages : desktopImages;

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // Change slide every 5 seconds

    return () => clearInterval(interval);
  }, [images]);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <div className="carousel">
      <button className="carousel-button prev" onClick={prevSlide} aria-label="Previous slide">
        ❮
      </button>
      <div className="carousel-container" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
        {images.map((image, index) => (
          <div key={index} className="carousel-slide" style={{ backgroundImage: `url(${image})` }}>
          </div>
        ))}
      </div>
      <button className="carousel-button next" onClick={nextSlide} aria-label="Next slide">
        ❯
      </button>
    </div>
  );
};

export default ImageCarousel;
